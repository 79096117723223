import React from 'react';
import { useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import io from 'socket.io-client'
import './index.css';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import { useAlert } from 'react-alert'

var _ = require('lodash');

// import App from './App';
// import * as serviceWorker from './serviceWorker';

var socket = io('https://numbers.bahman.me');
// var socket = io('http://localhost:8010');


const alert_options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE
}

function MainMenu(props){
  return (
    <div className="container">
      <div className="vbox">
        <div id="main_logo">
          Numbers!
        </div>
        <button className="s_btn menu_btn margin_lr" onClick={() => props.onClick(6)}>Join a game</button>
        <button className="s_btn menu_btn margin_lr" onClick={() => props.onClick(2)}>Create a new game</button>
        <button className="s_btn menu_btn margin_lr" onClick={() => props.onClick(1)}>About</button>
      </div>
    </div>
  );
}

function AboutPage(props){
  return (
    <div className="container">
      <div className="vbox margin_lr center">
        <button className="material-icons back_btn s_btn" onClick={props.return_func}>arrow_back</button>
        <div className="about_discription">
        Numbers is an online multiplayer game. The rules are pretty straight forward:<br/>
        Each player has a set of numbers which the number of owned numbers by each player is equal to the current level of the game. To win the game, All of the players should play their numbers in an ascending order, pretty simple, right?<br/>
        There is also another simple rule: players should not communicate with each other during the game; not in any form :)<br/>
        All of the mentioned numbers are in a definite range which has to be determined when creating a new game.<br/>
        Each group of players have a number of lives and stars. The use of the lives is obvious, if you run out of lives you lose and you have the start the game from beginning.<br/>
        And the stars... If all of the players in a game press the "Pass this level" button, They can pass the level by using one star (assuming thay have at least one star left).<br/>
        After creating a new game, A unique game id is generated, Which other players can use to join the game. The game starts when the number of the joined players reaches the amount that was defined when creating the game.<br/>
        If a player gets disconnected during the game, Other players can continue playing their remaining numbers without considering the numbers that the left player owned.<br/>
        If a player joins the game after the game has started he/she can watch the current round being played, But can't play until the next round.<br/>
        And that's it...<br/>
        Enjoy the game :)<br/>
        <br/><br/>
        .
        <br/><br/>
        .
        <br/><br/>
        .<br/><br/>
        This game is brought to you by Bahman :))
        <br/><br/><br/>
        </div>
      </div>
    </div>
  );
}
class GameSetup extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      name_value: '',
      nop_value: '2', // number of players
      range_value: '100',
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(event)
  {
    if (event.target.name === "name_input")
      this.setState({name_value: event.target.value});
    else if (event.target.name === "num_of_players_input")
      this.setState({nop_value: event.target.value});
    else if (event.target.name === "range_input")
      this.setState({range_value: event.target.value});
  }

  render() {
    return (
      <div className="container">
        <button className="material-icons back_btn s_btn" onClick={this.props.return_func}>arrow_back</button>
        <div className="vbox margin_lr">
          <div className="margin_top_7"></div>
          <form className="center vbox">
            <label htmlFor="name_input" className="form_label">
              Your Name:
            </label>
            <input className="form_input" type="text" value={this.state.name_value} onChange={this.handleChange} name="name_input" />
            <label htmlFor="num_of_players_input" className="form_label">
              Number of players:
            </label>
            <input className="form_input" type="number" min="2" value={this.state.nop_value} onChange={this.handleChange} name="num_of_players_input" />
            <label htmlFor="range_input" className="form_label">
              Game range (100-1000):
            </label>
            <input className="form_input" type="number" min="100" max="1000" value={this.state.range_value} onChange={this.handleChange} name="range_input" />
          </form>
          <button className="s_btn menu_btn" onClick={() => this.props.send_req(this.state)}>Create!</button>
        </div>
      </div>
    );
  }
}

class JoinGame extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      name_value: '',
      game_id_value: '',
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(event)
  {
    if (event.target.name === "name_input")
      this.setState({name_value: event.target.value});
    else if (event.target.name === "game_id_input")
      this.setState({game_id_value: event.target.value});
  }

  render() {
    return (
      <div className="container">
        <button className="material-icons back_btn s_btn" onClick={this.props.return_func}>arrow_back</button>
        <div className="vbox margin_lr">
          <div className="margin_top_7"></div>
          <form className="center vbox">
          <label htmlFor="name_input" className="form_label">
              Your Name:
            </label>
            <input className="form_input" type="text" value={this.state.name_value} onChange={this.handleChange} name="name_input" />
            <label htmlFor="name_input" className="form_label">
              Game ID:
            </label>
            <input className="form_input" type="text" value={this.state.game_id_value} onChange={this.handleChange} name="game_id_input" />
          </form>
          <button className="s_btn menu_btn" onClick={() => this.props.send_req(this.state)}>Join!</button>
        </div>
      </div>
    );
  }
}

class WaitPage extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      name_value: '',
      game_id_value: '',
    };
  }

  render() {
    const joined_players = this.props.joined_players.map((name, index) =>
      <div className="name_list_item" key={index}>
        {name}
      </div>
    );
    return (
      <div className="container">
        <div className="vbox margin_lr center">
          <button className="material-icons back_btn s_btn" onClick={this.props.return_func}>close</button>
          <div className="margin_top_10"></div>
          <div className="text2">Waiting for other players to join...</div>
          <div className="text0">Game ID:</div>
          <div className="text1">{this.props.game_id}</div>
          <div className="margin_top_3"></div>
          <div className="text0">Joined players:</div>
          <div className="name_list">
            {joined_players}
          </div>
        </div>
      </div>
    );
  }
}


function GamePlay(props){
  const [cur_level, set_cur_level] = useState(0);
  const [last_card_no, set_last_card_no] = useState("");
  const [last_card_player, set_last_card_player] = useState("");
  const [owned_cards, set_owned_cards] = useState([]);
  const [lives_number, set_lives_number] = useState(0);
  const [stars_number, set_stars_number] = useState(0);

  const [next_card_to_play, set_next_card_to_play] = useState(0);
  const [played_by_indicator, set_played_by_indicator] = useState("");
  const [remaining_numbers_indicator, set_remaining_numbers_indicator] = useState("Remaining numbers: ");

  const [show_notif, set_show_notif] = useState(false);
  const [notif_title, set_notif_title] = useState("");
  const [notif_msg, set_notif_msg] = useState("");

  const[play_btn_enabled, set_play_btn_enabled] = useState(false);
  const[use_star_btn_enabled, set_use_star_btn_enabled] = useState(false);

  const alert = useAlert();

  useEffect(() => {
    socket.off("game_sit");
    socket.off("game_updated");
    socket.off("level_ended");
    socket.off("play_next_num");
    socket.off("use_star_sug");

    socket.on('game_sit', function (data) {
      console.log("game sit", data);
      set_cur_level(data.cur_level);
      set_lives_number(data.lives);
      set_stars_number(data.stars);
      
      set_last_card_no(data.last_card_no);
      set_last_card_player(data.last_card_player);

      let owned_cards = data.owned_cards.map((value) => {return parseInt(value)});
      owned_cards.sort(function(a, b){return a-b});
      set_owned_cards(owned_cards);

      if (owned_cards.length > 0)
        set_next_card_to_play(owned_cards[0]);
      else
        set_next_card_to_play("");

      if (data.last_card_player.length > 0)
        set_played_by_indicator("Played by: ");
      else
        set_played_by_indicator("");

      set_play_btn_enabled(data.can_play);
      set_use_star_btn_enabled(data.can_play);

      if (owned_cards.length === 0){
        set_play_btn_enabled(false);
        set_remaining_numbers_indicator("No numbers remained!");
      }
      else{
        set_remaining_numbers_indicator("Remaining numbers: ");
      }
      if (parseInt(data.stars) === 0){
        set_use_star_btn_enabled(false);
      }

    });

    socket.on('game_updated', function (data) {
      socket.emit("game_sit");
    });

    socket.on('level_ended', function (data) {
      set_notif_title(data.msg_t);
      set_notif_msg(data.msg);
      set_show_notif(true);
    });

    socket.on('play_next_num', function (data) {
      if (data.res === "FAILED"){
        alert.show(data.msg);
      }
    });

    socket.on('use_star_sug', function(data){
      if (data.res === "FAILED"){
        alert.show(data.msg);
      }
      else{
        alert.show(data.suggester_name + " suggested to pass this level using a star");
      }
    });

  });

  useEffect(() => {
    socket.emit("game_sit");
  }, []);

  function play(){
    if (play_btn_enabled){
      socket.emit("play_next_num");
    }
  }

  function suggest_star_usage(){
    socket.emit("use_star_sug"); // star usage suggestion
    set_use_star_btn_enabled(false);
  }

  function notif_btn_handler(){
    socket.emit("game_sit");
    set_show_notif(false);
  }

  let notif_box = (
    <div className="notif_box">
      <div className="container full_height_p">
        <div className="notif_message_box">
          {notif_title}<br/>
          {notif_msg}
        </div>
        <button className="notif_button s_btn" onClick={notif_btn_handler}>
          OK
        </button>
      </div>
    </div>
  );
  if (!show_notif){
    notif_box = "";
  }
  return(
    <>
    {notif_box}
    <div className = "container full_height">
      <button className="material-icons back_btn s_btn" onClick={props.open_menu}>menu</button>
      <div id="star_box">
        <span className="material-icons" id="gp_star_ico">star</span>
        <span className="two_dots">:</span>
        <span className="gp_value_0">{stars_number}</span>
      </div>
      <div id="lives_box">
        <span className="material-icons" id="gp_lives_ico">favorite</span>
        <span className="two_dots">:</span>
        <span className="gp_value_0">{lives_number}</span>
      </div>
      <div className="gp_top_box">
        <div className="gp_level_indc">Level: {cur_level}</div>
        <button className="gp_use_star_btn s_btn" onClick={suggest_star_usage} disabled={!use_star_btn_enabled}>Pass this level!</button>
      </div>
      <div className="gp_last_played_card_box">
        <div className="gp_lpc_no">
          {last_card_no}
        </div>
        <div className="gp_lpc_player_name">
          {played_by_indicator}{last_card_player}
        </div> 
      </div>
      <div className="gp_bottom_box">
        <button className="gp_play_btn s_btn" onClick={play} disabled={!play_btn_enabled}>Play {next_card_to_play}</button>
        <div className="gp_remaining_nums_list">{remaining_numbers_indicator}{owned_cards.join(", ")}</div>
      </div>
    </div>
    </>
  );
}

function GameMenu(props){
  console.log(props);
  const joined_players = props.joined_players.map((name, index) =>
      <div className="name_list_item" key={index}>
        {name}
      </div>
    );
    return (
      <div className="container">
        <div className="vbox margin_lr center">
          <button className="material-icons back_btn s_btn" onClick={props.return_func}>close</button>
          <div className="margin_top_10"></div>
          <div className="text0">Game ID:</div>
          <div className="text1">{props.game_id}</div>
          <div className="margin_top_3"></div>
          <div className="text0">Players:</div>
          <div className="name_list">
            {joined_players}
          </div>
          <div className="margin_top_7"></div>
          <div className="text0">Range:</div>
          <div className="text0">{props.game_range}</div>
          <div className="margin_top_3"></div>
          <button className="gm_quit_btn s_btn" onClick={props.quit_handler}>Quit game</button>
        </div>
      </div>
    );
}

function Main(props){
  const [current_page, set_current_page] = useState(0); // page_index: 0 -> main_menu / 1 -> about / 2 -> game_setup / 3 -> waiting / 4 -> game_play / 5 -> game_menu / 6 -> join
  const [game_id, set_game_id] = useState('000000');

  const [joined_players, set_joined_players] = useState([]);
  const [game_range, set_game_range] = useState(100);

  const alert = useAlert();
  
  useEffect(() => {
    socket.off("create_game");
    socket.off("join_game");
    socket.off("player_joined");
    socket.off("player_disconnected");
    socket.off("quit_game");
    socket.off("game_start");

    socket.on('create_game', function (data) {
      console.log("create game", data);
      if (data.res === 'OK'){
        set_game_id(data.game_id);
        set_joined_players(data.joined_players);
        set_game_range(data.game_range);
        set_current_page(3);
      }
      else{
        alert.error(data.msg);
      }
    });
    socket.on('join_game', function (data) {
      console.log("join game", data);
      if (data.res === 'OK'){
        set_game_id(data.game_id);
        set_joined_players(data.joined_players);
        set_game_range(data.game_range);
        set_current_page(3);

        if (!data.has_right_to_play){
          alert.show("You can play from the next round.");
        }
      }
      else{
        alert.error(data.msg);
      }
    });

    socket.on('player_joined', function (data) {
      set_joined_players(_.concat(joined_players, [data.player_name]));
      alert.info(data.player_name+" joined!");    
    });

    socket.on('quit_game', function (data) {
      set_current_page(0);
      alert.info("Quitted the game!");    
    });

    socket.on('player_disconnected', function (data) {
      console.log(data);
      let tmp = joined_players.slice(0);
      _.remove(tmp, function(s) {
        return s === data;
      });
      set_joined_players(tmp);
      alert.info(data+" disconnected!");    
    });

    socket.on('game_start', function (data) {
      set_current_page(4);  
    });


  });

  // function for sending create_game request
  function create_game(gp){
    socket.emit("create_game", {name: gp.name_value, range: gp.range_value, nplayers: gp.nop_value});
  }

  // function for sending join game request
  function join_game(gp){
    let game_id = gp.game_id_value.toUpperCase();
    socket.emit("join_game", {game_id: game_id, name: gp.name_value});
  }
  function quit_game(){
    socket.emit("quit_game");
    set_current_page(0);
  }

  const btn_navigate_handler = (i) => {
    set_current_page(i);
  };
  if (current_page === 0){
    return (
      <MainMenu
        onClick = {i => btn_navigate_handler(i)}
      />
    );
  }
  if (current_page === 1){
    return (
      <AboutPage
        return_func = {() => btn_navigate_handler(0)} 
      />
    );
  }
  else if (current_page === 2){
    return (
      <GameSetup
        return_func = {() => btn_navigate_handler(0)} 
        send_req = {create_game}
      />
    );
  }
  else if (current_page === 3){
    return (
      <WaitPage
        return_func = {quit_game}
        game_id = {game_id} 
        joined_players = {joined_players}
      />
    );
  }
  else if (current_page === 4){
    return (
      <GamePlay
        open_menu = {() => {set_current_page(5);}}
      />
    );
  }
  else if (current_page === 5){
    return (
      <GameMenu
        return_func = {() => {set_current_page(4);}}
        game_id = {game_id} 
        joined_players = {joined_players}
        game_range = {game_range}
        quit_handler = {quit_game}
      />
    );
  }
  else if (current_page === 6){
    return (
      <JoinGame
        return_func = {() => btn_navigate_handler(0)} 
        send_req = {join_game}
      />
    );
  }
}

ReactDOM.render(
  <>
    <AlertProvider template={AlertTemplate} {...alert_options}>
      <Main/>
    </AlertProvider>
  </>
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();